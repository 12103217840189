<template>
    <v-combobox v-bind="$attrs" v-on="$listeners" :items="sortedItems" :rules="rules" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({inheritAttrs: false})
export default class SortedCombobox extends Vue {
    @Prop() items: string[];
    @Prop() rules: Array<((v:any)=>boolean|string)|string|boolean>;

    get sortedItems(): string[] {
        return [...this.items].sort((a, b) => a?.localeCompare(b));
    }
}
</script>