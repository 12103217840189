enum StoreGetter {
    //Challenge
    GetChallenges = "getChallenges",
    IsChallengesFetched = 'isChallengesFetched',
    GetChallengeCategories = 'getChallengeCategories',
    //SubmissionStore
    GetSubmissions = "getSubmissions",
    IsSubmissionFetching = "isSubmissionFetching",
    IsFetchedSubmission = "isFetchedSubmission",
    IsSolved = "isSolved",
    IsSubmissionFetched = "isSubmissionFetched",
    GetTeamSubmissions = "getTeamSubmissions",
    GetLatestSubmissionTimestamp = "getLatestSubmissionTimestamp",
    GetLatestCorrectionTimestamp = 'getLatestCorrectionTimestamp',
    MyTeamsChallengesStatistics = "MyTeamsChallengesStatistics",
    //Alert
    GetAlerts = "getAlerts",
}

export default StoreGetter;
